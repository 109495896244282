@import "./src/scss/mixins/mixins";

.item {
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    padding: 15px;
    background: #FDFDFD;

    &:first-child {
         margin-top: 30px !important;
    }
}


.uploadBtn {
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 14px;
    height: 155px;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    color: #444;
}

.dmgWrap {
    & > div {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(5, 1fr);
    }
}

.warningIcon {
    height: 50px;
    width: 50px;
    margin-bottom: 10px;
}

