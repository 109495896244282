@import "./src/scss/mixins/mixins";


.label {
  font-size: 16px;
  color: #808080;
  font-weight: 600;
  white-space: nowrap;
}

.value {
  font-weight: 400;
  color: #313F4E;
}

.galleryBtn {
  color: #313F4E;
  border: 0;
  background: transparent;
  @include flexbox();
  @include align-items(flex-end);

  svg {
    font-size: 28px;
  }
}

.vin {
  color: #313F4E;
  font-weight: 700;
}

.meta {
  font-size: 14px;

  .label {
    font-size: 14px;
  }
}

.metaWrapper {
  @include flexbox();
  @include flex-direction(column);
  margin-right: 20px;

  &:last-child {
    margin: 0;
  }
}

.inlineMetaWrapper {
  @include flexbox();
  @include flex-direction(row);
  margin-right: 20px;

  &:last-child {
    margin: 0;
  }

  .value {
    margin-left: 5px;
  }
}
