/**
 * Columns
 *
 */
@mixin columns( $columns, $grid-gap) {

	& > div, & > article {
		float: left;
		margin: 0 0 $grid-gap $grid-gap;
		width: calc((100% / #{$columns}) - (#{$grid-gap}));
		@include media-breakpoint-down(md) {
			width: calc((100% / #{2}) - (#{$grid-gap}));
		}

		&:nth-of-type(#{$columns}n+1) {
			clear: both;
			margin-left: 0;
		}
	}



	@supports( grid-area: auto ) {
		display: grid;
		grid-gap: $grid-gap;
		grid-template-columns: repeat( $columns, 1fr );
		@include media-breakpoint-down(md) {
			grid-template-columns: repeat( 2, 1fr );
		}
		@include media-breakpoint-down(sm) {
			grid-template-columns: repeat( 1, 1fr );
		}

		& > div, & > article {
			margin: 0 !important;
			width: 100% !important;
		}
	}
}
