@import "./src/scss/mixins/mixins";
@import "./src/custom";

#side-navigation {
  background: #313F4E;
  position: fixed;
  left: 0;
  top: 60px;
  height: calc(100% - 60px);
  width: 250px;
  max-width: 250px;
  @include flexbox();

  @include media-breakpoint-down(md) {
    z-index: 99;
    display: none;
  }

  .menu, .nav-menu {
    width: 100%;
    @include flexbox();
  }

  .nav-menu {
    @include flex-direction(column);

    .menu-item {

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .iconWrapper {
    width: 25px;
    margin-left: auto;
    flex-grow: 0;
    flex-shrink: 0;
    cursor: pointer;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }


  &.closed {
    width: 60px;

    header {
      padding: 0;
      @include flexbox();
      @include justify-content(center);
    }

    .iconWrapper {
      margin-right: 0;
    }

    .logo, .label {
      display: none;
    }

    .menu-item {
      a {
        @include justify-content(center);
      }
    }
  }

  .logo-small {
    width: auto;
    height: 60px;
    max-width: 100%;
  }

  .iconWrapper {

    svg {

      path {
        fill: $brand-secondary !important;
      }
    }
  }

  header {
    height: 70px;
    padding: 0 0 0 25px;
  }

  .nav-menu {
    padding: 0;

    .menu-item {
      &.small {
        border-top: unset;
        &:last-child {
          border-bottom: unset;
        }
        a, & > span {
          font-size: 15px;
          height: 36px;
        }
      }

      &.settings {
        span {
          font-size: 15px;
          text-transform: uppercase;
        }
      }
      list-style-type: none;
      border-top: 1px solid #405062;

      &.has-border {
        border-bottom: 1px solid #405062;
      }

      &:last-child {
        border-bottom: 1px solid #405062;
      }

      &:first-child {
        border-top: 0;
      }

      a, & > span {
        height: 50px;
        @include flexbox();
        @include align-items(center);
        padding: 0 20px;
        font-size: 17px;
        font-weight: 500;
        color: #fff;
        text-decoration: none;

        &.selected {
          color: $brand-secondary;

          svg {

            path {
              fill: $brand-secondary;
            }
          }
        }
      }
    }
  }
}
