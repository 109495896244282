.name {
  letter-spacing: 0;
  color: #313F4E;
  font-weight: bold;
  font-size: 21px;
  margin: 0;
}

.metaOuter {
  font-size: 13px;
}
