.damageData {
  display: none;

  &.open {
    display: block;
  }
}

.damageInnerData {
  td {
    padding: 0 10px;
  }
}
