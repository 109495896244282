@import "variables";
@import "scss/mixins/mixins";
// Override default variables before the import
//$body-bg: #000;
// Import Bootstrap and its default variables

.cursor-pointer {
  cursor: pointer;
}

body {
  .c-item-accordion-btn {
    background: transparent !important;
    border: 0 !important;
    width: auto;
    height: auto;
    margin-left: 10px;
    padding: 0;
    box-shadow: none !important;
  }
}


#sidebar {
  min-width: 250px;
  max-width: 250px;
  color: #fff;
  transition: all 0.3s;
}

#sidebar.active {
  margin-left: -250px;
}

#sidebar .sidebar-header {
  padding: 20px;
  background: #6d7fcc;
}

#sidebar ul.components {
  padding: 20px 0;
  border-bottom: 1px solid #47748b;
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}

#sidebar ul li a:hover {
  color: #7386D5;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
  color: #fff;
  background: #6d7fcc;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #6d7fcc;
}

/* ---------------------------------------------------
  CONTENT STYLE
----------------------------------------------------- */

#content {
  width: 100%;
  min-height: 100vh;
  transition: all 0.3s;
}

/* ---------------------------------------------------
  MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  #sidebar {
      margin-left: -250px;
  }
  #sidebar.active {
      margin-left: 0;
  }
  #sidebarCollapse span {
      display: none;
  }
}

.history-grid {
  grid-gap: 10px !important;
}

.swal2-html-container {
  z-index: 20 !important;
}

.select-order-modal-wrapper {
  * {
    text-align: left !important;
  }
}

.ds-select {
  font-size: 13px !important;
  height: 38px !important;
  padding-right: 5px !important;
  background-size: 10px 10px !important;
  background-position-x: 94% !important;
}

.file-upload-modal {
  .swal2-file {
    position: relative;
    border: 0;

    //&::-webkit-file-upload-button {
    //  visibility: hidden;
    //}
    //
    &:after {
      content: 'or drop files here';
      border: 2px dashed #ccc;
      border-radius: 4px;
      position: relative;
      width: 100%;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      background: #EFEFEF;
    }
  }
  //.swal2-input-label {
  //  border: 2px dashed #ccc;
  //  border-radius: 4px;
  //  position: relative;
  //  width: 100%;
  //  height: 150px;
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  margin-bottom: 20px;
  //  background: #EFEFEF;
  //
  //}
  //
  //.swal2-title {
  //  order: 1;
  //}
  //
  //#swal2-input {
  //  order: 2;
  //}
  //
  //.swal2-input-label {
  //  order: 3;
  //}
  //
  //.swal2-actions {
  //  order: 4;
  //}
}




.react-autosuggest__input {
  @extend .form-control;
  background: #F3F3F3 !important;
  color: $brand-primary !important;
}

.form-check {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  min-height: unset !important;
  height: 22px !important;
}

.form-control[type="file"], .react-autosuggest__input[type="file"] {
  background: #fff !important;
}

.edit-order-gallery {

  .gallery-wrapper {
    @extend .layout-grid-4;

    .img-item {
      height: 150px;
      display: block;
    }

    .gallery-counter {
      display: none;
    }
  }
}

.gallery-wrapper {

  .gallery-counter {
    position: absolute;
    background: $brand-secondary;
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
    padding: 4px 8px;
    border-radius: 5px;
    bottom: 10px;
    right: 10px;
    line-height: 1;
    font-weight: 500;
  }

  .img-item {
    display: none;
    height: 260px;
    border-radius: 5px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }

    &:nth-child(1) {
      display: block;
    }
  }
}

.filter-multi-select {
  width: 300px;
}

.attachments-list {
  grid-gap: 3px !important;
}

.metas {
  .data-item {
    @include flexbox();
    @include align-items(center);

    .count {
      font-size: 34px;
      font-weight: 700;
      color: #313F4E;
      margin-left: auto;
      position: relative;
      line-height: 1;

      &:before {
        content: '';
        position: relative;
        display: block;
        width: calc(100% + 10px);
        right: 10px;
        top: 0;
        margin-bottom: 5px;
        height: 4px;
        background: $brand-secondary;
      }
    }

    .label {
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
      width: 60%;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
}

.react-autosuggest__container {
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 100%;
  position: relative;
}

.react-autosuggest__suggestions-container {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #f3f3f3;
  color: $brand-primary;
}

.control-btn {
  //margin-right: 10px;
  background: $brand-primary;
  width: 30px;
  height: 30px;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 50%;
  font-size: 14px;
  border: 0;
  color: #fff;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  transition: 0.3s ease-in-out all;

  &:hover {
    color: $brand-secondary;
  }


  &:last-child {
    margin: 0;
  }
}

.btn {
  line-height: 1 !important;
}

.errors {
  * {
    color: red !important;
  }
}

.pagination-wrapper {
  padding: 0;
  margin: 30px -5px 0 -5px;
  @include flexbox();
  @include justify-content(flex-end);
  list-style-type: none;

  .previous, .next {
    display: none;
  }

  li {
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    width: 30px;
    height: 30px;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0 5px;
    font-weight: 500;

    &:not(.break) {
      a {
        width: 100%;
        height: 100%;
        border: 2px solid #808080;
        border-radius: 3px;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        text-align: center;
      }
    }

    &.selected {

      a {
        background: $brand-secondary;
        color: #fff;
        border-color: $brand-secondary;

        &:hover {
          color: #fff;
        }
      }
    }
  }
}

.text-decoration-none {
  text-decoration: none;
}

.table-fixed-height {
  height: 350px;
  overflow: scroll;
}

.entry-table {

  tr {
    background: #fff;

    th, td {
      padding: 10px;
    }

    &:nth-child(2n) {
      background: #ececec;
    }
  }
}

.small-gap {
  grid-gap: 10px !important;
}

.card {

  &:not(.no-shadow) {
    box-shadow: 0 7px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 1px solid #e3e3e3;
  }

  &.no-border {
    border: 0;
  }
}

.container {
  max-width: 100% !important;
  @media all and (max-width: 1780px){
    margin-left: auto !important;
    margin-right: 40px !important;
  }
}

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 1280px,
        xl: 1480px
);



#main {
  padding: 100px 0 50px 0;
}

.large-gap {
  grid-gap: 30px !important;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.dmg-img-wrapper {
  height: 155px;
  border-radius: 5px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }

  @media (min-width: 2400px) {
    min-height: 340px;
  }
}

.btn-min {
  font-size: 15px;
  font-weight: 600;
  color: $brand-primary;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.card {
  text-decoration: none;

  &.accordion-item {
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
    background: #fff;
  }
}

$spacer: 20px;

$spacers: (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 2.5),
    6: 60px,
    7: 70px,
    8: 80px,
    9: 90px,
    10: 100px,
    11: 110px,
    12: 120px,
    13: 130px,
    14: 140px,
    15: 150px,
    16: 15px,
    60: 60px
);
$enable-negative-margins: true;

.nowrap {
  white-space: nowrap;
}
.text-row {
  background-color: rgb(232, 232, 232) !important;

  &:nth-child(3n) {
    margin-bottom: 40px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}


.muted {
  font-weight: 600;
  color: #858585;
}

.strong {
  font-weight: 600;
  color: #3a3a3c;
}

.meta-wrapper {
  font-size: 15px;
  display: block;
  line-height: 1;
}

.form-group {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.highlight {
  color: red;
}

.workingHoursTable {
  color: #858585;
  font-size: 15px;
  width: 50%;

  tr {

    &.active {
      th, td {
        font-weight: 700;
        color: #3a3a3c;
      }
    }
  }

  th {
    vertical-align: top;
    font-weight: 500;
  }

  td {
    font-weight: 600;
  }
}

.border-radius {
  border-radius: 5px !important;
}

.form-heading-wrapper {
  @extend .h2;
}

.form-paragraph-wrapper {
  font-size: 1rem;
}

.form-heading-wrapper, .form-paragraph-wrapper {
  .control-label {
    display: none;
  }
}

$modal-inner-padding: 1rem;

@import "~bootstrap/scss/bootstrap";
@import "scss/mixins/mixins";

.circle-count {
  width: 47px;
  height: 47px;
  background: #CCCCCC;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 50%;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  letter-spacing: 0;
  color: #313F4E;
  font-size: 20px;
  font-weight: 600;
}

.text-decoration-none {
  text-decoration: none !important;
}

.mid-fixer {

  @include media-breakpoint-up(md) {
    margin: auto;
    transform: translateX(-50%);
  }
}

.copy-success {
  position: absolute;
  display: inline-block;
  line-height: 1;
  background: $brand-secondary;
  color: $body-color;
  font-weight: 600;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  padding: 4px 8px;
  border-radius: 5px;

  &:empty {
    display: none;
  }
}

.h-38 {
  height: 38px;
}

.btn-min {
  background: transparent;
  padding: 0;
  border: 0;
  color: $brand-primary;
}

.radio-inline {

  & > span {
    margin-right: 10px;

    input {
      margin-right: 5px;
    }
  }
}

.checkbox {
  input {
    margin-right: 5px;

  }
}

.form-paragraph-wrapper {

  p, span {
    font-size: 1rem !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
    font-weight: normal !important;
  }
}

.expenses-row {
  td,th {
    text-align: left;
  }
}

.react-autosuggest__suggestions-list {
  @include flexbox();
  @include flex-direction(column);
  padding: 5px 10px;

  .react-autosuggest__suggestion {
    list-style-type: none;
    cursor: pointer;
  }
}

.has-border {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: -11px;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 20px;
    background: $brand-secondary;
  }
}

.advancedSearchTrigger {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-weight: 700;
}

.btn-secondary {
  color: #fff !important;
}

.form-group {
  position: relative;
}

.gallery-wrapper {
  cursor: pointer;
}

.grouped-orders-wrapper {
  grid-gap: 60px !important;
}

@for $i from 1 through 12 {
  .gallery-grid-#{$i} {
    @include columns(#{$i}, 20px);
  }
}

@for $i from 1 through 12 {
  .layout-grid-#{$i} {
    @include columns(#{$i}, 15px);
  }
}

@for $i from 1 through 12 {
  .gallery-columns-#{$i} {
    @include columns(#{$i}, 20px);
  }
}

@media (max-width: 1280px) {
  .layout-grid-1 {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}


.file-upload-div {
  display: flex;
  content: "or drop files here";
  border: 2px dashed #ccc;
  border-radius: 4px;
  position: relative;
  width: 100%;
  height: 155px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  background: #EFEFEF;
}