$body-bg: #f6f6f6;
$primary: #313F4E;
$secondary: #A38362;
$sidebar-bg: #00586a;
$card-cap-bg: #fff;
$brand-primary: $primary;
$brand-secondary: $secondary;
$dark: #232323;
$btn-primary-bg: $brand-primary;
$btn-secondary-color: #fff;
$btn-font-weight: 600;


$h2-font-size:                24px;
