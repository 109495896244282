.signature {
  max-height: 150px;
  object-fit: contain;
}

.deleteSignature {
  position: absolute;
  top: 25px;
  right: 10px;
  &:hover {
    cursor: pointer;
  }
}