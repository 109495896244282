@import "./src/variables";
@import "./src/scss/mixins/mixins";

.count {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  line-height: 1;
  font-weight: 600;
  color: #fff;
  background: $brand-secondary;
  margin-left: -10px;
}
