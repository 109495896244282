@import "./src/variables";
@import "./src/scss/mixins/mixins";

.controlsWrapper {
  position: absolute;
  left: 10px;
  top: 10px;
}

.control {
  margin-right: 10px;
  background: #fff;
  width: 35px;
  height: 35px;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 50%;
  border: 0;
  color: $brand-secondary;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);

  &:hover {
    color: $brand-secondary;
  }


  &:last-child {
    margin: 0;
  }
}
