@import "./src/scss/mixins/mixins";
@import "./src/variables";

.navbar {
  background: #fff;
  border-bottom: 1px solid #cacaca;
  height: 60px;
  @include flexbox();
  @include align-items(center);
  font-size: 15px;
  z-index: 10;
  position: fixed;
  width: 100%;
}

.initials {
  width: 50px;
  height: 50px;
  background: $brand-primary;
  color: #fff;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 50%;
  font-size: 24px;
  font-weight: 600;

  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
}

.name {
  font-size: 15px;
  font-weight: 600;
}

.logout {
  margin-left: 15px;
}


.searchWrapper {
  margin-left: 160px;
  width: 500px;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 100%;
  position: relative;
}

.searchInput {
  background: #F3F3F3 !important;
  color: $brand-primary !important;
}



.navWrapper {
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
}
