@import "./src/variables";
@import "./src/scss/mixins/mixins";

.controls {
  position: absolute;
  top: 10px;
  right: 10px;
  @include flexbox();
}

.control {
  margin-right: 10px;
  background: #fff;
  width: 35px;
  height: 35px;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 50%;
  border: 0;
  color: $brand-secondary;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);

  &:hover {
    color: $brand-secondary;
  }


  &:last-child {
    margin: 0;
  }
}

.aresDragAndDrop {
  content: "or drop files here";
  border: 2px dashed #ccc;
  border-radius: 4px;
  position: relative;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  background: #EFEFEF;
}
